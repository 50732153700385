import type { IconProps } from "@chakra-ui/react";
import { OnlineDoctorChIcon } from "../../components/icons/OnlineDoctorChIcon";
import { OnlineDoctorDeIcon } from "../../components/icons/OnlineDoctorDeIcon";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitterX,
} from "react-icons/bs";

import { CityInfo, type Doctor, Locale } from "../types";
import type { IconType } from "react-icons";
import { germanTheme, swissTheme } from "@onlinedoctor-ch/docustyle";

interface TenantFeatures {
  insurances: boolean;
  default_city_search: CityInfo[];
}

export interface TenantConfiguration {
  name: "ch" | "de";
  id: string;
  theme: Record<string, any>;
  features: TenantFeatures;
  defaultLocale: Locale;
  enabledPatientLocales: Locale[];
  iconComponent: React.ComponentType<IconProps>;
  googleMapId: string;
  address: {
    companyName: string;
    line1: string;
    line2: string;
  };
  links: {
    homePage: string;
    legal: { name: string; url: { [key: string]: string } }[];
    navigationMenu: { name: string; url: { [key: string]: string } }[];
    doctorSearchNavigationMenu: {
      name: string;
      url: { [key: string]: string };
    }[];
    social: {
      name: string;
      url: string;
      iconComponent: IconType;
    }[];
    doctorProfileFAQonCMS: { [key: string]: string };
  };
  doctorSearchAlternateUrlsInOtherDomains: { [key: string]: string };
  getDoctorFaqEntries: (doctor: Doctor) => FaqEntry[];
  base_price: number;
  currency: "CHF" | "EUR";
  baseUrl: string;
}

const tenantIds = process.env.tenantIds as unknown as Record<string, string>;

export const tenantConfigurations: TenantConfiguration[] = [
  {
    name: "ch",
    id: tenantIds.ch,
    theme: swissTheme,
    defaultLocale: Locale["de-ch"],
    enabledPatientLocales: [
      Locale.de,
      Locale.en,
      Locale.fr,
      Locale.it,
      Locale["de-ch"],
    ],
    features: {
      insurances: false,
      default_city_search: [],
    },
    iconComponent: OnlineDoctorChIcon,
    googleMapId: "b2483305f67f8fa7",
    address: {
      companyName: "OnlineDoctor AG",
      line1: "address.online_doctor_ch_address_line1",
      line2: "address.online_doctor_ch_address_line2",
    },
    links: {
      homePage: "https://www.onlinedoctor.ch",
      navigationMenu: [
        {
          name: "links.dermatologists",
          url: {
            de: "https://www.onlinedoctor.ch/doctors/",
            "de-ch": "https://www.onlinedoctor.ch/doctors/",
            en: "https://www.onlinedoctor.ch/en/doctors/",
            fr: "https://www.onlinedoctor.ch/fr/doctors/",
            it: "https://www.onlinedoctor.ch/it/doctors/",
          },
        },
      ],
      doctorSearchNavigationMenu: [
        {
          name: "links.howItWorks",
          url: {
            de: "https://www.onlinedoctor.ch/wie-funktioniert-onlinedoctor/",
            "de-ch":
              "https://www.onlinedoctor.ch/wie-funktioniert-onlinedoctor/",
            en: "https://www.onlinedoctor.ch/en/how-it-works/",
            fr: "https://www.onlinedoctor.ch/fr/comment-fonctionne-onlinedoctor/",
            it: "https://www.onlinedoctor.ch/it/come-funziona-onlinedoctor/",
          },
        },
        {
          name: "links.dermatologists",
          url: {
            de: "https://www.onlinedoctor.ch/doctors/",
            "de-ch": "https://www.onlinedoctor.ch/doctors/",
            en: "https://www.onlinedoctor.ch/en/doctors/",
            fr: "https://www.onlinedoctor.ch/fr/doctors/",
            it: "https://www.onlinedoctor.ch/it/doctors/",
          },
        },
        {
          name: "links.aboutUs",
          url: {
            de: "https://www.onlinedoctor.ch/partnerversicherungen/",
            "de-ch": "https://www.onlinedoctor.ch/partnerversicherungen/",
            en: "",
            fr: "",
            it: "",
          },
        },
        {
          name: "links.skin_lexicon",
          url: {
            de: "https://www.onlinedoctor.ch/hautlexikon/",
            "de-ch": "https://www.onlinedoctor.ch/hautlexikon/",
            en: "",
            fr: "",
            it: "",
          },
        },
        {
          name: "links.faqs",
          url: {
            de: "https://www.onlinedoctor.ch/faq/",
            "de-ch": "https://www.onlinedoctor.ch/faq/",
            en: "https://www.onlinedoctor.ch/en/faq/",
            fr: "https://www.onlinedoctor.ch/fr/faq/",
            it: "https://www.onlinedoctor.ch/it/faq/",
          },
        },
        {
          name: "links.for_doctors",
          url: {
            de: "https://www.onlinedoctor.ch/fuer-aertzinnen-aerzte/",
            "de-ch": "https://www.onlinedoctor.ch/fuer-aertzinnen-aerzte/",
            en: "https://www.onlinedoctor.ch/en/for-doctors/",
            fr: "https://www.onlinedoctor.ch/fr/pour-les-medecins/",
            it: "https://www.onlinedoctor.ch/it/per-i-medici/",
          },
        },
      ],
      legal: [
        {
          name: "links.site_notice",
          url: {
            de: "https://www.onlinedoctor.ch/impressum/",
            "de-ch": "https://www.onlinedoctor.ch/impressum/",
            en: "https://www.onlinedoctor.ch/en/site-notice/",
            fr: "https://www.onlinedoctor.ch/fr/mentions-legales/",
            it: "https://www.onlinedoctor.ch/it/colophon/",
          },
        },
        {
          name: "links.agb",
          url: {
            de: "https://www.onlinedoctor.ch/agb/",
            "de-ch": "https://www.onlinedoctor.ch/agb/",
            en: "https://www.onlinedoctor.ch/en/gtc/",
            fr: "https://www.onlinedoctor.ch/fr/cgv/",
            it: "https://www.onlinedoctor.ch/it/cgc/",
          },
        },
        {
          name: "links.data_protection_online_doctor_ag",
          url: {
            de: "https://www.onlinedoctor.ch/datenschutzerklaerung/",
            "de-ch": "https://www.onlinedoctor.ch/datenschutzerklaerung/",
            en: "https://www.onlinedoctor.ch/en/data-protection/",
            fr: "https://www.onlinedoctor.ch/fr/protection-des-donnees/",
            it: "https://www.onlinedoctor.ch/it/protezione-dei-dati/",
          },
        },
        {
          name: "links.medical_device",
          url: {
            de: "https://www.onlinedoctor.ch/medizinprodukt/",
            "de-ch": "https://www.onlinedoctor.ch/medizinprodukt/",
            en: "https://www.onlinedoctor.ch/en/medical-device/",
            fr: "https://www.onlinedoctor.ch/fr/dispositif-medical/",
            it: "https://www.onlinedoctor.ch/it/dispositivo-medico/",
          },
        },
      ],
      social: [
        {
          name: "Facebook",
          url: "https://www.facebook.com/onlinedoctor.ch",
          iconComponent: BsFacebook,
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/onlinedoctor.ch/",
          iconComponent: BsInstagram,
        },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/company/onlinedoctor/mycompany/",
          iconComponent: BsLinkedin,
        },
        {
          name: "Twitter",
          url: "https://twitter.com/OnlinedoctorAG",
          iconComponent: BsTwitterX,
        },
      ],
      doctorProfileFAQonCMS: {
        de: "https://www.onlinedoctor.ch/de/faq/",
        en: "https://www.onlinedoctor.ch/en/faq/",
        fr: "https://www.onlinedoctor.ch/fr/faq/",
        it: "https://www.onlinedoctor.ch/it/faq/",
        "de-ch": "https://www.onlinedoctor.ch/de/faq/",
      },
    },
    getDoctorFaqEntries: (_) => [
      {
        question: "doctorProfile.FAQ.what_disease.question",
        answer: "doctorProfile.FAQ.what_disease.answer.ch",
      },
      {
        question:
          "doctorProfile.FAQ.can_i_see_a_dermatologist_without_an_appointment.question",
        answer:
          "doctorProfile.FAQ.can_i_see_a_dermatologist_without_an_appointment.answer",
      },
      {
        question:
          "doctorProfile.FAQ.how_quickly_will_i_receive_a_diagnosis.question",
        answer:
          "doctorProfile.FAQ.how_quickly_will_i_receive_a_diagnosis.answer",
      },
      {
        question: "doctorProfile.FAQ.how_much_does_it_cost.question",
        answer: "doctorProfile.FAQ.how_much_does_it_cost.answer.ch",
      },
      {
        question: "doctorProfile.FAQ.where_can_i_download_my_invoice.question",
        answer: "doctorProfile.FAQ.where_can_i_download_my_invoice.answer",
      },
    ],
    base_price: 55,
    currency: "CHF",
    baseUrl: process.env.baseUrlCH!,
    doctorSearchAlternateUrlsInOtherDomains: {
      de: "https://www.onlinedoctor.de/de/doctors/",
    },
  },
  {
    name: "de",
    id: tenantIds.de,
    theme: germanTheme,
    features: {
      insurances: true,
      default_city_search: [
        {
          name: "frankfurt",
          radius: 20000,
        },
        {
          name: "berlin",
          radius: 30000,
        },
        {
          name: "hamburg",
          radius: 20000,
        },
        {
          name: "allgaeu",
          radius: 20000,
        },
        {
          name: "ansbach",
          radius: 20000,
        },
        {
          name: "dortmund",
          radius: 20000,
        },
        {
          name: "hannover",
          radius: 20000,
        },
        {
          name: "koeln",
          radius: 20000,
        },
        {
          name: "mannheim",
          radius: 20000,
        },
        {
          name: "muenchen",
          radius: 20000,
        },
        {
          name: "stuttgart",
          radius: 20000,
        },
      ],
    },
    defaultLocale: Locale.de,
    enabledPatientLocales: ["local", "dev"].includes(
      process.env.environment || ""
    )
      ? [Locale.de, Locale.en]
      : [Locale.de],
    iconComponent: OnlineDoctorDeIcon,
    googleMapId: "9c67637200e06020",
    address: {
      companyName: "OnlineDoctor 24 GmbH",
      line1: "address.online_doctor_de_address_line1",
      line2: "address.online_doctor_de_address_line2",
    },
    links: {
      homePage: "https://www.onlinedoctor.de",
      navigationMenu: [
        {
          name: "links.dermatologists",
          url: {
            de: "https://www.onlinedoctor.de/doctors/",
            en: "https://www.onlinedoctor.de/doctors/",
          },
        },
        {
          name: "links.partnerInsurance",
          url: {
            de: "https://www.onlinedoctor.de/partnerversicherungen/",
            en: "https://www.onlinedoctor.de/partnerversicherungen/",
          },
        },
      ],
      doctorSearchNavigationMenu: [
        {
          name: "links.howItWorks",
          url: {
            de: "https://www.onlinedoctor.de/wie-funktioniert-onlinedoctor/",
            en: "https://www.onlinedoctor.de/wie-funktioniert-onlinedoctor/",
          },
        },
        {
          name: "links.dermatologists",
          url: {
            de: "https://www.onlinedoctor.de/doctors/",
            en: "https://www.onlinedoctor.de/doctors/",
          },
        },
        {
          name: "links.partnerInsurance",
          url: {
            de: "https://www.onlinedoctor.de/partnerversicherungen/",
            en: "https://www.onlinedoctor.de/partnerversicherungen/",
          },
        },
        {
          name: "links.skin_lexicon",
          url: {
            de: "https://www.onlinedoctor.de/hautlexikon/",
            en: "https://www.onlinedoctor.de/hautlexikon/",
          },
        },
        {
          name: "links.faqs",
          url: {
            de: "https://www.onlinedoctor.de/faq/",
            en: "https://www.onlinedoctor.de/faq/",
          },
        },
        {
          name: "links.for_doctors",
          url: {
            de: "https://www.onlinedoctor.de/fuer-aertzinnen-aerzte/",
            en: "https://www.onlinedoctor.de/fuer-aertzinnen-aerzte/",
          },
        },
      ],
      legal: [
        {
          name: "links.abb",
          url: {
            de: "https://www.onlinedoctor.de/abb/",
            en: "https://www.onlinedoctor.de/abb/",
          },
        },
        {
          name: "links.agb",
          url: {
            de: "https://www.onlinedoctor.de/agb/",
            en: "https://www.onlinedoctor.de/agb/",
          },
        },
        {
          name: "links.data_protection_dermatologists",
          url: {
            de: "https://www.onlinedoctor.de/datenschutz-dermatologinnen/",
            en: "https://www.onlinedoctor.de/datenschutz-dermatologinnen/",
          },
        },
        {
          name: "links.data_protection_online_doctor",
          url: {
            de: "https://www.onlinedoctor.de/datenschutzerklaerung/",
            en: "https://www.onlinedoctor.de/datenschutzerklaerung/",
          },
        },
        {
          name: "links.site_notice",
          url: {
            de: "https://www.onlinedoctor.de/impressum/",
            en: "https://www.onlinedoctor.de/impressum/",
          },
        },
        {
          name: "links.medical_device",
          url: {
            de: "https://www.onlinedoctor.de/medizinprodukt/",
            en: "https://www.onlinedoctor.de/medizinprodukt/",
          },
        },
      ],
      social: [
        {
          name: "Facebook",
          url: "https://www.facebook.com/onlinedoctor.de/",
          iconComponent: BsFacebook,
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/onlinedoctor.de/",
          iconComponent: BsInstagram,
        },
        {
          name: "Linkedin",
          url: "https://www.linkedin.com/company/onlinedoctor/mycompany/",
          iconComponent: BsLinkedin,
        },
        {
          name: "Twitter",
          url: "https://twitter.com/OnlinedoctorAG",
          iconComponent: BsTwitterX,
        },
      ],
      doctorProfileFAQonCMS: {
        de: "https://www.onlinedoctor.de/faq/",
      },
    },
    getDoctorFaqEntries: (doctor) => [
      {
        question: "doctorProfile.FAQ.what_disease.question",
        answer: "doctorProfile.FAQ.what_disease.answer.de",
      },
      {
        question:
          "doctorProfile.FAQ.can_i_see_a_dermatologist_without_an_appointment.question",
        answer:
          "doctorProfile.FAQ.can_i_see_a_dermatologist_without_an_appointment.answer",
      },
      {
        question:
          "doctorProfile.FAQ.how_quickly_will_i_receive_a_diagnosis.question",
        answer:
          "doctorProfile.FAQ.how_quickly_will_i_receive_a_diagnosis.answer",
      },
      doctor.legal_information?.germany?.is_gkv_member
        ? {
            question:
              "doctorProfile.FAQ.which_health_insurance_cover_the_costs.question",
            answer:
              "doctorProfile.FAQ.which_health_insurance_cover_the_costs.answer",
          }
        : {
            question: "doctorProfile.FAQ.how_much_does_it_cost.question",
            answer: "doctorProfile.FAQ.how_much_does_it_cost.answer.pkv_de",
          },
      {
        question: "doctorProfile.FAQ.where_can_i_download_my_invoice.question",
        answer: "doctorProfile.FAQ.where_can_i_download_my_invoice.answer",
      },
    ],
    base_price: 38.87,
    currency: "EUR",
    baseUrl: process.env.baseUrlDE!,
    doctorSearchAlternateUrlsInOtherDomains: {
      de: "https://www.onlinedoctor.ch/de/doctors/",
      "de-ch": "https://www.onlinedoctor.ch/de-ch/doctors/",
      en: "https://www.onlinedoctor.ch/en/doctors/",
      fr: "https://www.onlinedoctor.ch/fr/doctors/",
      it: "https://www.onlinedoctor.ch/it/doctors/",
    },
  },
];

interface FaqEntry {
  question: string;
  answer: string;
}

const tenantConfigurationsById: { [id: string]: TenantConfiguration } = {};
tenantConfigurations.forEach(
  (config) => (tenantConfigurationsById[config.id] = config)
);

export const getFrontendTenantConfigurationById = (tenantId: string) =>
  tenantConfigurationsById[tenantId];

export const getFrontendTenantConfigurationByName = (tenantName: string) =>
  tenantConfigurations.find((config) => config.name === tenantName);
